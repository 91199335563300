import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/pagination/style";
import _Pagination from "vant/es/pagination";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "core-js/modules/es.array.push.js";
import { taskPerformInfo, taskPerformDetail } from '@/api/wxproject';
export default {
  components: {
    [_Cell.name]: _Cell,
    [_Pagination.name]: _Pagination,
    [_Tab.name]: _Tab,
    [_Tabs.name]: _Tabs,
    [_Empty.name]: _Empty
    // [ImagePreview.name]: ImagePreview,
  },

  data() {
    return {
      currentPage: 1,
      // page:1,
      success: false,
      active: 0,
      projectdate: {},
      projectdatelist: [],
      type1: '',
      activeName: ''
    };
  },
  mounted() {
    console.log(this.$route.query);
    document.title = '触点活跃详情';
    this.activeName = this.$route.query.activeName;
    this.status1 = this.$route.query.status1;
    this.type1 = this.$route.query.type1;
    this.getDate();
  },
  methods: {
    getDate() {
      taskPerformInfo({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        orgTerminalCode: this.$route.query.orgTerminalCode,
        userCode: this.$route.query.userCode,
        planCode: this.$route.query.planCode,
        date: this.$route.query.date
      }).then(res => {
        this.success = res.data.success;
        this.projectdate = res.data.data;
        this.gettaskDate();
      });
    },
    gettaskDate() {
      taskPerformDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        orgTerminalCode: this.$route.query.orgTerminalCode,
        taskCode: this.projectdate.data[0].taskCode,
        userCode: this.$route.query.userCode,
        planCode: this.$route.query.planCode,
        date: this.$route.query.date
      }).then(res1 => {
        this.projectdatelist = res1.data.data;
        console.log(res1);
      });
    },
    changeactive(index) {
      if (this.active === index) {
        return;
      }
      this.active = index;
      taskPerformDetail({
        projectCode: this.$route.query.projectCode,
        month: this.$route.query.month,
        orgTerminalCode: this.$route.query.orgTerminalCode,
        taskCode: this.projectdate.data[index].taskCode,
        userCode: this.$route.query.userCode,
        planCode: this.$route.query.planCode,
        date: this.$route.query.date
      }).then(res1 => {
        this.projectdatelist = res1.data.data;
        this.currentPage = 1;
        console.log(res1);
      });
    },
    changepage() {
      this.page = this.currentPage;
    },
    goback1() {
      this.$router.push({
        path: '/ActiveDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type,
          activeName: this.activeName,
          date: this.$route.query.date,
          status: this.$route.query.status,
          searchname: this.$route.query.searchname,
          province: this.$route.query.province,
          city: this.$route.query.city,
          area: this.$route.query.area
        }
      });
    },
    goback2() {
      window.history.back();
    },
    preview(src) {
      _ImagePreview([src]);
    }
  }
};